import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import styled from 'styled-components';
import HomeBanner from '../components/HomeBanner';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  color: #333;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LeagueTableContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 20px;
  }
`;

const LeagueTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const TableHeader = styled.th`
  background-color: #ecf0f1;
  color: #2c3e50;
  padding: 10px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const TableRow = styled.tr`
  position: relative;
  background-color: ${props => {
    if (props.position <= 8) return '#d4edda'; // Green for top 4
    if (props.position >= 9) return '#f8d7da'; // Red for bottom 2
    return '#ffffff'; // Default background
  }};
  
  font-weight: ${props => props.position === 1 ? 'bold' : 'normal'}; // Bold text for 1st place
  
  &:nth-child(even) {
    background-color: ${props => {
      if (props.position <= 8) return '#d4edda';
      if (props.position >= 9) return '#f8d7da';
      return '#ffffff'; // Default for even rows
    }};
  }
`;

const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: ${props => props.position === 1 ? '#155724' : '#2c3e50'}; // Dark green for 1st place text color

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 10px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 15px;
    padding-bottom: 8px;
  }
`;

const ClickableTitle = styled.h2`
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  text-decoration: none;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 10px;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 15px;
    padding-bottom: 8px;
  }
`;

const PreviewContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: none; // Remove any max-height limitation
  overflow: visible; // Ensure content is not hidden

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 20px;
  }
`;

const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 1px solid #ecf0f1;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const TeamNames = styled.span`
  font-weight: bold;
  color: #2c3e50;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Score = styled.span`
  color: #0A2F53;
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const DateTime = styled.span`
  color: #0A2F53;
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

function Home() {
  const [leagueStandings, setLeagueStandings] = useState([]);
  const [latestResults, setLatestResults] = useState([]);
  const [nextFixtures, setNextFixtures] = useState([]);

  useEffect(() => {
    const standingsCollection = collection(db, 'leaguestandings');
    const fixturesCollection = collection(db, 'fixtures');

    // League Standings
    const unsubscribeStandings = onSnapshot(standingsCollection, (snapshot) => {
      const standingsList = snapshot.docs.map(doc => doc.data());

      let formattedStandings = standingsList.map((team) => {
        const win_count = parseInt(team.win_count, 10);
        const draw_count = parseInt(team.draw_count, 10);
        const loss_count = parseInt(team.loss_count, 10);
        const total_points = parseInt(team.total_points, 10);

        return {
          ...team,
          win_count,
          draw_count,
          loss_count,
          h2h_points: (win_count * 3) + draw_count,
          games_played: win_count + draw_count + loss_count,
          draft_points: total_points,
        };
      });

      // Sort by H2H points first, then by DP (Draft Points)
      formattedStandings.sort((a, b) => {
        if (b.h2h_points === a.h2h_points) {
          return b.draft_points - a.draft_points;
        }
        return b.h2h_points - a.h2h_points;
      });

      // Add position to each team
      formattedStandings = formattedStandings.map((team, index) => ({
        ...team,
        position: index + 1,
      }));

      setLeagueStandings(formattedStandings);
    });

    // Latest Results
    const resultsQuery = query(
      fixturesCollection,
      where('fixture_date', '<', new Date()),
      where('result', '!=', ''),
      orderBy('fixture_date', 'desc')
    );

    const unsubscribeResults = onSnapshot(resultsQuery, (snapshot) => {
      const resultsList = snapshot.docs.map(doc => doc.data());
      setLatestResults(resultsList);
    });

    // Upcoming Fixtures
    const fixturesQuery = query(
      fixturesCollection,
      where('fixture_date', '>', new Date()),
      orderBy('fixture_date', 'asc')
    );

    const unsubscribeFixtures = onSnapshot(fixturesQuery, (snapshot) => {
      const fixturesList = snapshot.docs.map(doc => doc.data());
      setNextFixtures(fixturesList);
    });

    // Cleanup function
    return () => {
      unsubscribeStandings();
      unsubscribeResults();
      unsubscribeFixtures();
    };
  }, []);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return `${date.toLocaleDateString()} at ${date.getHours() % 12 || 12}${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  };

  return (
    <Container>
      <HomeBanner />
      
      <LeagueTableContainer>
        <SectionTitle>Cup Standings</SectionTitle>
        <TableWrapper>
          <LeagueTable>
            <thead>
              <tr>
                <TableHeader>Pos</TableHeader>
                <TableHeader>Team</TableHeader>
                <TableHeader>GP</TableHeader>
                <TableHeader>W</TableHeader>
                <TableHeader>D</TableHeader>
                <TableHeader>L</TableHeader>
                <TableHeader>PD</TableHeader>
                <TableHeader>H2H</TableHeader>
              </tr>
            </thead>
            <tbody>
              {leagueStandings.map((team, index) => (
                <TableRow key={index} position={team.position}>
                  <TableData position={team.position}>{team.position}</TableData>
                  <TableData position={team.position}>{team.entry_name}</TableData>
                  <TableData position={team.position}>{team.games_played}</TableData>
                  <TableData position={team.position}>{team.win_count}</TableData>
                  <TableData position={team.position}>{team.draw_count}</TableData>
                  <TableData position={team.position}>{team.loss_count}</TableData>
                  <TableData position={team.position}>{team.draft_points}</TableData>
                  <TableData position={team.position}>{team.h2h_points}</TableData>
                </TableRow>
              ))}
            </tbody>
          </LeagueTable>
        </TableWrapper>
      </LeagueTableContainer>
      
      <PreviewContainer>
        <ClickableTitle onClick={() => window.location.href = '/results'}>Latest Results ({latestResults.length})</ClickableTitle>
        {latestResults.slice(0, 5).map((result, index) => (
          <PreviewItem key={index}>
            <TeamNames>{result.home_team} vs {result.away_team}</TeamNames>
            <Score>{result.result}</Score>
          </PreviewItem>
        ))}
      </PreviewContainer>

      <PreviewContainer>
        <ClickableTitle onClick={() => window.location.href = '/fixtures'}>Upcoming Fixtures ({nextFixtures.length})</ClickableTitle>
        {nextFixtures.slice(0, 5).map((fixture, index) => (
          <PreviewItem key={index}>
            <TeamNames>{fixture.home_team} vs {fixture.away_team}</TeamNames>
            <DateTime>{formatDateTime(fixture.fixture_date)}</DateTime>
          </PreviewItem>
        ))}
      </PreviewContainer>
    </Container>
  );
}

export default Home;
